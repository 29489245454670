.masonry-grid {
  display: flex;
  gap: 8px;
}

.masonry-grid_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto !important;
}