.accordion-custom-table {
    .MuiCollapse-root {
        background-color: #e4e4e4;
        .MuiAccordionDetails-root {
            background-color: #ffffff;
            border-radius: 16px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            margin: 8px 0 16px 16px;
            padding: 8px 0 0 0;
        }
    }
}
